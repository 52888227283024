import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store";
import ProjectListPage from "./../views/ProjectListPage";
import PageNotFound from "./../views/PageNotFound";
import ProjectDetailPage from "./../views/ProjectDetailPage";
import TablePage from "./../views/TablePage";
import PromptDetailsPage from "./../views/PromptDetailsPage";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "ProjectListPage",
      component: ProjectListPage,
    },
    {
      path: "/prompts",
      name: "PromptDetailsPage",
      component: PromptDetailsPage,
      props: true,
    },
    {
      path: "/projects/:projectId",
      name: "ProjectDetailPage",
      component: ProjectDetailPage,
    },
    {
      path: "/projects/:projectId/:tableId",
      name: "TablePage",
      component: TablePage,
    },
    {
      path: "/:catchAll(.*)",
      component: PageNotFound,
      name: "PageNotFound",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  next();
});

export default router;
