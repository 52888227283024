<template>
  <div>
    <v-list v-if="!isDataAvailable">
      <v-list-item-content>
        <v-list-item-subtitle> No Result found </v-list-item-subtitle>
      </v-list-item-content>
    </v-list>
    <v-layout v-else>
      <v-carousel
        height="inherit"
        show-arrows-on-hover
        hide-delimiter-background
      >
        <v-carousel-item v-for="result in currentRowList" :key="result.id">
          <v-card class="pb-10" outlined>
            <v-card-subtitle>{{ result.response.answer }}</v-card-subtitle>
            <v-card-text>{{ result.ts }}</v-card-text>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ResultComponent",
  data: () => ({
    currentRowList: [],
  }),
  props: {
    list: {
      required: true,
      type: Array,
    },
    rowId: {
      required: true,
      type: String,
    },
  },
  computed: {
    isDataAvailable: function () {
      const index = this.list.findIndex((item) => item.rowId === this.rowId);
      return index !== -1;
    },
  },
  mounted() {
    this.getCurrentRow();
  },
  methods: {
    getCurrentRow() {
      this.list.forEach((item) => {
        if (item.rowId == this.rowId) {
          this.currentRowList.push(item);
        }
      });
    },
  },
};
</script>

<style>
.v-carousel__controls__item {
  color: var(--gray02) !important;
}
.v-carousel__controls__item.v-btn.v-btn--icon .v-icon {
  font-size: 8px !important;
}

.v-carousel__controls__item.v-btn.v-btn--icon.v-btn--active {
  background-color: var(--greendark) !important;
}

.v-carousel__controls__item.v-btn.v-btn--icon.v-size--small {
  height: 8px;
  width: 8px;
}
</style>
