import { HTTP } from "./../../../functions/http-common";
import { Types } from "./../../types";

export const actions = {
  [Types.actions.DO_TOKEN]: ({ commit }, payload) => {
    commit(Types.mutations.SET_TOKEN, payload);
  },
  [Types.actions.DO_UPDATE_SNACKBAR]: ({ commit }, payload) => {
    commit(Types.mutations.SET_SNACKBAR, payload);
  },
  [Types.actions.DO_MODAL_DETAIL]: ({ commit }, payload) => {
    commit(Types.mutations.SET_MODAL_DETAIL, payload);
  },
  [Types.actions.DO_PROJECT_DETAIL]: ({ dispatch, commit, state }, payload) => {
    const { projectId, loader, params } = payload
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_TABLES, { data: [] })
    if (loader) commit(Types.mutations.SET_LOADING_STATUS, true)
    HTTP.get(`/projects/${projectId}`, config)
      .then((result) => {
        commit(Types.mutations.SET_PROJECT_DETAIL, result.data);
        if (params) dispatch(Types.actions.DO_GET_RESULTS, { params, projectId });
        if (loader) {
          dispatch(Types.actions.DO_GET_DOCUMENT, projectId);
          if (
            result.data &&
            result.data.contextTables &&
            result.data.contextTables.length
          ) {
            dispatch(Types.actions.DO_GET_TABLES, { projectId });
          } else {
            commit(Types.mutations.SET_LOADING_STATUS, false);
          }
        }
      })
      .catch(() => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
      });
  },
  [Types.actions.DO_DELETE_PROJECT]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { url } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.delete(url, config)
      .then((result) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_DELETE_PROJECT, {});
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: 'DeleteModal'
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          name: 'DeleteModal'
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_SETTING]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { url, vectorStoreId } = payload;
    commit(Types.mutations.SET_SETTING_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.delete(url, config)
      .then((result) => {
        delete state.vectorsStatus[vectorStoreId];
        commit(Types.mutations.SET_SETTINGS, state.vectorsStatus);
        commit(Types.mutations.SET_SETTING_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: 'DeleteSettingModal'
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_SETTING_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "Something went wrong! Please try again",
          name: 'DeleteSettingModal'
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_DOCUMENT]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { projectId, document } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.delete(`/projects/${projectId}/documents?delDocument=${document}`, config)
      .then((result) => {
        const updatedDocumentList = state.documents.filter(item => item !== document)
        commit(Types.mutations.SET_DOCUMENTS, updatedDocumentList);
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: 'DeleteDocumentModal'
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "Something went wrong! Please try again",
          name: 'DeleteDocumentModal'
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_USER]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { projectId, userId } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.delete(`/projects/${projectId}/users?delUser=${userId}`, config)
      .then((result) => {
        commit(Types.mutations.SET_USERS, userId);
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: 'DeleteUserModal'
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "Something went wrong! Please try again",
          name: 'DeleteUserModal'
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_TABLE]: ({ dispatch, commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { projectId, url } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.delete(url, config)
      .then((result) => {
        dispatch(Types.actions.DO_GET_TABLES, { projectId });
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: 'DeleteUserModal'
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "Something went wrong! Please try again",
          name: 'DeleteUserModal'
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_ADD_USER]: ({ dispatch, commit, state }, payload) => {
    const { projectId, requestBody } = payload
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.post(`/projects/${projectId}/users`, requestBody, config).then(
      (response) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
        });
        dispatch(Types.actions.DO_PROJECT_DETAIL, { projectId, loader: false, params: undefined });
        commit(Types.mutations.SET_LOADING_STATUS, false);
        state.snackbar.showSnackbar({
          message: "User Added Successfully",
          color: "success",
        });
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        console.log(error);
      },
    );
  },
  [Types.actions.DO_GET_TABLES]: ({ commit, state }, payload) => {
    const { projectId, tableId } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_LOADING_STATUS, true);
    HTTP.get(`/projects/${projectId}/tables`, config)
      .then((result) => {
        commit(Types.mutations.SET_TABLES, { data: result.data, tableId });
        commit(Types.mutations.SET_LOADING_STATUS, false);
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
      });
  },
  [Types.actions.DO_UPLOAD_TABLE]: ({ dispatch, commit, state }, payload) => {
    const { project_id } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.post(`/projects/${project_id}/tables`, payload, config)
      .then((response) => {
        dispatch(Types.actions.DO_GET_TABLES, { projectId: project_id });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
        });
        state.snackbar.showSnackbar({
          message: "Table Uploaded Successfully",
          color: "success",
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        console.error("Error adding table:", error);
      });
  },
  [Types.actions.DO_UPDATE_COMMENT]: ({ dispatch, commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { projectId } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.post("/queue/lineCommentary", payload, config).then(
      (response) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        state.snackbar.showSnackbar({
          message: "Updated Commentary Successfully",
          color: "success",
        });
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        state.snackbar.showSnackbar({
          message: "API call failed",
          color: "error",
          icon: "mdi-alert",
        });
        console.log(error);
      },
    );
  },
  [Types.actions.DO_UPDATE_QNA]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_LOADING_STATUS, true);
    HTTP.post("/queue/qna", payload, config).then(
      (response) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        state.snackbar.showSnackbar({
          message: "Updated QnA Successfully",
          color: "success",
        });
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        state.snackbar.showSnackbar({
          message: "API call failed",
          color: "error",
          icon: "mdi-alert",
        });
        console.log(error);
      },
    );
  },
  [Types.actions.DO_GET_RESULTS]: ({ commit, state }, payload) => {
    const { params, projectId } = payload;
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_SETTING_LOADING_STATUS, true);
    HTTP.post(`/retrieve/results/${projectId}`, params, config).then(
      (response) => {
        if (response.data) {
          switch (params.queueMethod) {
            case "qna":
              commit(Types.mutations.SET_QNA, response.data);
              break;
            case "lineCommentary":
              commit(Types.mutations.SET_COMMENTARY, response.data);
              break;
            case "vectorise":
              commit(Types.mutations.SET_VECTOR_STATUS, response.data);
          }
        }
        commit(Types.mutations.SET_SETTING_LOADING_STATUS, false);
      },
      (error) => {
        commit(Types.mutations.SET_SETTING_LOADING_STATUS, false);
        console.log(error);
      },
    );
  },
  [Types.actions.DO_UPDATE_RESULTS]: ({ commit }, payload) => {
    commit(Types.mutations.SET_UPDATE_RESULTS, payload);
  },
  [Types.actions.DO_ADD_NEW_SETTING]: (
    { dispatch, commit, state },
    payload,
  ) => {
    const { projectId, requestBody } = payload
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.post(`/projects/${projectId}/settings`, requestBody, config).then(
      (response) => {
        const params = {
          queueMethod: "vectorise",
        };
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
        });
        dispatch(Types.actions.DO_PROJECT_DETAIL, { projectId, loader: false, params });
        commit(Types.mutations.SET_LOADING_STATUS, false);
        state.snackbar.showSnackbar({
          message: "Setting Added Successfully",
          color: "success",
        });
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        console.log(error);
      },
    );
  },
  [Types.actions.DO_VECTORISE_SETTING]: ({ dispatch, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { projectId } = payload;
    /* eslint-disable */
    HTTP.post("/queue/vectorise", payload, config).then(
      (response) => {
        const params = {
          queueMethod: "vectorise",
        };
        dispatch(Types.actions.DO_PROJECT_DETAIL, {projectId, loader: false, params});
      },
      (error) => {
        console.log(error);
      },
    );
  },
  [Types.actions.DO_GET_DOCUMENT]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_DOCUMENTS, []);
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.get(`/projects/${payload}/documents`, config).then(
      (response) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_DOCUMENTS, response.data);
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        console.log(error);
      },
    );
  },
  [Types.actions.DO_UPDATE_SELECTED_ROW]: ({ commit }, payload) => {
    commit(Types.mutations.SET_SELECTED_ROW, payload);
  },
  [Types.actions.DO_UPDATE_TABLE_STATUS]: ({ commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.post("/projects/table/status", payload, config).then(
      (response) => {
        commit(Types.mutations.SET_TABLE_STATUS, response.data);
        commit(Types.mutations.SET_LOADING_STATUS, false);
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        console.log(error);
      },
    );
  },
  [Types.actions.DO_UPLOAD_DOCUMENT]: ({ dispatch, commit, state }, payload) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    const { projectId, requestBody, lastItem } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, true);
    /* eslint-disable */
    HTTP.post(`/projects/${projectId}/documents`, requestBody, config).then(
      (response) => {
        if (lastItem) {
          dispatch(Types.actions.DO_GET_DOCUMENT, projectId);
          commit(Types.mutations.SET_MODAL_DETAIL, {
            showDialog: false,
            errorMessage: "",
            name: "DocumentModal",
          });
          commit(Types.mutations.SET_LOADING_STATUS, false);
        }
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
          name: "DocumentModal",
        });
        console.log(error);
      },
    );
  },
};
