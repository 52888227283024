<template>
  <v-app>
    <v-app-bar dark absolute src="./assets/background.png" app short>
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top, rgba(246,246,250,.4), rgba(46,46,56,.9)"
        ></v-img>
      </template>
      <!-- Icon Button -->
      <v-btn icon :to="`/prompts`">
        <v-icon>mdi-text-box</v-icon>
        <!-- or mdi-lightbulb -->
      </v-btn>
      <v-toolbar-title>Diligence as a Service</v-toolbar-title>
      <v-spacer></v-spacer>
      <AuthComponent v-if="this.msal.isAuthenticated" />
    </v-app-bar>

    <v-main class="offwhite">
      <v-container v-if="!this.msal.isAuthenticated">
        <v-row class="my-4 d-flex justify-center offwhite">
          <v-btn @click="signIn()">Sign In</v-btn>
        </v-row>
        <v-row class="my-4 d-flex justify-center offwhite">
          <v-btn text class="gray02--text" @click="href()">Sign Out</v-btn>
        </v-row>
      </v-container>
      <v-container v-else class="offwhite">
        <router-view :admin="admin"/>
        <snackbar ref="snackbar" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AuthComponent from "./components/AuthComponent.vue";
import { msalMixin } from "vue-msal";
import { HTTP } from "./functions/http-common";
import SnackbarComponent from './components/SnackbarComponent.vue'

export default {
  name: "App",
  mixins: [msalMixin],
  components: {
    AuthComponent,
    snackbar: SnackbarComponent
  },
  data() {
    return {
      admin: false
    };
  },
  mounted() {
    this.getSignInStatus();
    this.$root.snackbar = this.$refs.snackbar
  },
  methods: {
    async getSignInStatus() {
      await this.$msal.acquireToken();
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`,
        },
      };
      await HTTP.get("/user/me", config).then(
        (response) => {
          this.admin = response.data.roles.includes("admin");
        },
        (error) => {
          console.log(error);
        }
      );
    },
    signIn() {
      this.$msal.signIn();
    }
  },
};
</script>
