<template>
  <div>
    <!-- Toolbar with Actions -->
    <v-toolbar flat>
      <v-btn color="secondary" dark @click="goBack" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="py-2"
        >{{ projectDetail.name }} <br />
        <span class="text-caption">{{ projectId }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="grey"
        dark
        @click="updateModalDetail({ showDialog: true, name: 'DeleteModal' })"
        icon
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-toolbar>
    <div v-if="modalDetails.showDialog && modalDetails.name === 'DeleteModal'">
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Delete Project </template>
        <template v-slot:body>
          <v-row>
            <v-card-text
              >Are you sure, you want to proceed with Delete?
            </v-card-text>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row v-if="modalDetails.errorMessage" no-gutters>
            <v-card-text class="red--text caption">{{
              modalDetails.errorMessage
            }}</v-card-text>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5">
              <v-checkbox
                v-model="isDelete"
                label="Hard Delete"
                density="compact"
                class="ma-0 pa-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="7" class="d-flex flex-row-reverse">
              <v-btn text color="error" @click="deleteProjects" small>
                Delete
              </v-btn>
              <v-btn
                text
                @click="
                  updateModalDetail({ showDialog: false, name: 'DeleteModal' })
                "
                small
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ModalLayout>
    </div>
    <LoaderComponent v-if="isLoading" />
    <template v-else>
      <TablesComponent :tableList="tableList" />
      <UserComponent />
      <ProjectSettings />
      <ProjectDocuments />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Types } from "./../store/types";
import LoaderComponent from "../components/LoaderComponent.vue";
import TablesComponent from "../components/TablesComponent.vue";
import UserComponent from "../components/UserComponent.vue";
import ProjectSettings from "../components/ProjectSettings.vue";
import ProjectDocuments from "../components/ProjectDocuments.vue";
import ModalLayout from "../components/ModalLayout.vue";
export default {
  name: "ProjectDetailPage",
  components: {
    LoaderComponent,
    TablesComponent,
    UserComponent,
    ProjectSettings,
    ProjectDocuments,
    ModalLayout,
  },
  data() {
    return {
      projectId: "",
      isDelete: false,
    };
  },
  async mounted() {
    this.setSnackbar(this.$root.snackbar);
    this.projectId = this.$route.params.projectId;
    await this.$msal.acquireToken();
    this.setToken(this.$msal.data.accessToken);
    this.refreshVectorStatus();
  },
  computed: {
    ...mapGetters("projectDetail", {
      tableList: Types.getters.GET_TABLES,
      isLoading: Types.getters.GET_LOADING_STATUS,
      projectDetail: Types.getters.GET_PROJECT_DETAIL,
      modalDetails: Types.getters.GET_MODAL_DETAIL,
    }),
  },
  methods: {
    ...mapActions("projectDetail", {
      getProjectDetails: Types.actions.DO_PROJECT_DETAIL,
      setToken: Types.actions.DO_TOKEN,
      deleteProject: Types.actions.DO_DELETE_PROJECT,
      setSnackbar: Types.actions.DO_UPDATE_SNACKBAR,
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
    }),
    async deleteProjects() {
      let url = "";
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      if (this.isDelete) {
        url = `/delete/project/${this.projectId}`;
      } else {
        url = `/projects/${this.projectId}`;
      }
      this.deleteProject({ url });
      this.goBack();
    },
    goBack() {
      this.$router.go(-1);
    },
    refreshVectorStatus() {
      const params = {
        queueMethod: "vectorise",
      };
      this.getProjectDetails({
        params,
        projectId: this.projectId,
        loader: true,
      });
    },
  },
};
</script>
