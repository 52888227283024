<template>
  <!-- Document Header -->
  <v-card tile class="py-2">
    <v-row no-gutters class="mx-2 align-center justify-center">
      <v-col cols="8">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Documents
        </v-card-title>
      </v-col>
      <v-col cols="4" class="d-flex flex-row-reverse">
        <v-btn v-if="hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          @click="
            updateModalDetail({ showDialog: true, name: 'DocumentModal' });
            uploadedFiles = [];
          "
          icon
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Document Content -->
    <template v-if="!hidden">
      <v-row class="mx-2" no-gutters v-if="Object.keys(documents).length">
        <v-row
          class="mx-2 d-flex justify-center align-center"
          no-gutters
          v-for="(document, index) in documents"
          :key="index"
        >
          <v-col cols="10">
            <v-card-text class="py-0">{{ document }}</v-card-text>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="grey"
              dark
              @click="
                updateModalDetail({
                  showDialog: true,
                  name: 'DeleteDocumentModal',
                  document,
                })
              "
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="mx-2" no-gutters v-else>
        <v-list-item>
          <v-list-item-subtitle>No Document Found.</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-btn
            outlined
            @click="
              updateModalDetail({ showDialog: true, name: 'DocumentModal' });
              uploadedFiles = [];
            "
            small
          >
            Add Document
          </v-btn>
        </v-list-item>
      </v-row>
    </template>

    <!-- Add Document Modal-->
    <div
      v-if="modalDetails.showDialog && modalDetails.name === 'DocumentModal'"
    >
      <ModalLayout :modal="modalDetails">
        <template v-slot:header> Upload Document </template>
        <template v-slot:body>
          <v-card @drop.prevent="onDrop" @dragover.prevent>
            <v-card-text>
              <v-row
                class="d-flex flex-column"
                dense
                align="center"
                justify="center"
              >
                <v-icon class="mt-5" size="60">mdi-cloud-upload</v-icon>
                <p>Drop your file(s) here, or click to select them.</p>
              </v-row>
              <v-virtual-scroll
                v-if="uploadedFiles.length > 0"
                :items="uploadedFiles"
                height="150"
                item-height="50"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.name" class="pa-0">
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ item.name }}
                        <span class="ml-1 text--secondary">
                          {{ item.size }} bytes</span
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action class="ma-0">
                      <v-btn @click.stop="removeFile(item.name)" icon>
                        <v-icon> mdi-close-circle </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                </template>
              </v-virtual-scroll>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                outlined
                small
                @click="
                  updateModalDetail({
                    showDialog: false,
                    name: 'DocumentModal',
                  })
                "
              >
                Close
              </v-btn>

              <v-btn
                outlined
                @click.stop="submit"
                small
                :disabled="!uploadedFiles.length"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </ModalLayout>
    </div>

    <!-- Delete Document Modal-->
    <div
      v-if="
        modalDetails.showDialog && modalDetails.name === 'DeleteDocumentModal'
      "
    >
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Delete Document </template>
        <template v-slot:body>
          <v-card-text class="px-0"
            >Are you sure, you want to proceed with Delete?
          </v-card-text>
        </template>
        <template v-slot:footer>
          <v-row v-if="modalDetails.errorMessage" no-gutters>
            <v-card-text class="red--text caption">{{
              modalDetails.errorMessage
            }}</v-card-text>
          </v-row>
          <v-row no-gutters class="d-flex flex-row-reverse">
            <v-btn text color="error" @click="deleteProjectDocument" small>
              Delete
            </v-btn>
            <v-btn
              text
              @click="
                updateModalDetail({
                  showDialog: false,
                  name: 'DeleteDocumentModal',
                })
              "
              small
            >
              Cancel
            </v-btn>
          </v-row>
        </template>
      </ModalLayout>
    </div>

    <!-- Document Footer -->
    <v-row class="mx-2 my-0" v-if="!hidden">
      <v-col class="d-flex flex-row-reverse py-0">
        <v-btn icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Types } from "../store/types";
import ModalLayout from "./ModalLayout.vue";

export default {
  name: "ProjectDocuments",
  components: {
    ModalLayout,
  },
  data: () => ({
    hidden: true,
    uploadedFiles: [],
    projectId: "",
  }),
  computed: {
    ...mapGetters("projectDetail", {
      projectDetail: Types.getters.GET_PROJECT_DETAIL,
      documents: Types.getters.GET_DOCUMENTS,
      modalDetails: Types.getters.GET_MODAL_DETAIL,
    }),
  },
  async mounted() {
    this.projectId = this.$route.params.projectId;
  },
  methods: {
    ...mapActions("projectDetail", {
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
      uploadDocuments: Types.actions.DO_UPLOAD_DOCUMENT,
      setToken: Types.actions.DO_TOKEN,
      getDocuments: Types.actions.DO_GET_DOCUMENT,
      deleteDocument: Types.actions.DO_DELETE_DOCUMENT,
    }),
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName,
      );
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
    onDrop(event) {
      event.preventDefault();
      let droppedFiles = event.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((element) => this.uploadedFiles.push(element));
    },
    async submit() {
      if (!this.uploadedFiles.length > 0) {
        console.log("There are no files to upload");
      } else {
        const projectId = this.$route.params.projectId;
        await this.$msal.acquireToken();
        this.setToken(this.$msal.data.accessToken);
        const uploadFileCount = this.uploadedFiles.length - 1;
        this.uploadedFiles.forEach((item, index) => {
          const formData = new FormData();
          formData.append("file", item);
          this.uploadDocuments({
            requestBody: formData,
            projectId,
            lastItem: uploadFileCount === index,
          });
        });
      }
    },
    async deleteProjectDocument() {
      const { document } = this.modalDetails;
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.deleteDocument({ projectId: this.projectId, document });
    },
  },
};
</script>
