var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"py-2",attrs:{"tile":""}},[_c('v-row',{staticClass:"mx-2 align-center justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card-title',{staticClass:"text-subtitle-2 text-uppercase"},[_vm._v(" Users ")])],1),_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"4"}},[(_vm.hidden)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.hidden = !_vm.hidden}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.updateModalDetail({ showDialog: true, name: 'UserModal' })}}},[_c('v-icon',[_vm._v("mdi-account-plus")])],1)],1)],1),(!_vm.hidden)?_c('v-simple-table',{staticClass:"mx-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("User ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Roles")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.projectDetail.users),function(user){return _c('tr',{key:user.userId},[_c('td',[_vm._v(_vm._s(user.userId))]),_c('td',[_vm._v(_vm._s(_vm.getUserRole(user.role)))]),_c('td',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.updateModalDetail({
                      showDialog: true,
                      name: 'DeleteUserModal',
                      userId: user.userId,
                    })}}},[_c('v-list-item-icon',{attrs:{"color":"grey","dark":""}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete User")])],1)],1)],1)],1)],1)])}),0)]},proxy:true}],null,false,4020125921)}):_vm._e(),(_vm.modalDetails.showDialog && _vm.modalDetails.name === 'UserModal')?_c('div',[_c('ModalLayout',{attrs:{"modal":_vm.modalDetails},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Add User ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"User ID","type":"email","required":""},model:{value:(_vm.addUser),callback:function ($$v) {_vm.addUser=$$v},expression:"addUser"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"my-0",attrs:{"items":Object.keys(_vm.availableRoles),"density":"compact","label":"Roles"},model:{value:(_vm.addRoles),callback:function ($$v) {_vm.addRoles=$$v},expression:"addRoles"}})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.modalDetails.errorMessage)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-text',{staticClass:"red--text caption"},[_vm._v(_vm._s(_vm.modalDetails.errorMessage))])],1):_vm._e(),_c('v-row',{staticClass:"d-flex flex-row-reverse",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.addUsers}},[_vm._v(" Add ")]),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.updateModalDetail({ showDialog: false, name: 'UserModal' });
              _vm.resetUser();}}},[_vm._v(" Cancel ")])],1)]},proxy:true}],null,false,3986944624)})],1):_vm._e(),(_vm.modalDetails.showDialog && _vm.modalDetails.name === 'DeleteUserModal')?_c('div',[_c('ModalLayout',{attrs:{"modal":_vm.modalDetails},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Delete User ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',[_c('v-card-text',[_vm._v("Are you sure, you want to proceed with Delete? ")])],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.modalDetails.errorMessage)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-text',{staticClass:"red--text caption"},[_vm._v(_vm._s(_vm.modalDetails.errorMessage))])],1):_vm._e(),_c('v-row',{staticClass:"d-flex flex-row-reverse",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"text":"","color":"error","small":""},on:{"click":() => _vm.deleteUsers(_vm.modalDetails.document)}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.updateModalDetail({
                showDialog: false,
                name: 'DeleteUserModal',
              })}}},[_vm._v(" Cancel ")])],1)]},proxy:true}],null,false,2398833518)})],1):_vm._e(),(!_vm.hidden)?_c('v-row',{staticClass:"mx-2 my-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.hidden = !_vm.hidden}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }