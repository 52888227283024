var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"py-2",attrs:{"tile":""}},[_c('v-row',{staticClass:"mx-2 align-center justify-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card-title',{staticClass:"text-subtitle-2 text-uppercase"},[_vm._v(" Documents ")])],1),_c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"4"}},[(_vm.hidden)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.hidden = !_vm.hidden}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.updateModalDetail({ showDialog: true, name: 'DocumentModal' });
          _vm.uploadedFiles = [];}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),(!_vm.hidden)?[(Object.keys(_vm.documents).length)?_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},_vm._l((_vm.documents),function(document,index){return _c('v-row',{key:index,staticClass:"mx-2 d-flex justify-center align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-card-text',{staticClass:"py-0"},[_vm._v(_vm._s(document))])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"grey","dark":"","icon":""},on:{"click":function($event){return _vm.updateModalDetail({
                showDialog: true,
                name: 'DeleteDocumentModal',
                document,
              })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),1):_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("No Document Found.")])],1),_c('v-list-item',[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":function($event){_vm.updateModalDetail({ showDialog: true, name: 'DocumentModal' });
            _vm.uploadedFiles = [];}}},[_vm._v(" Add Document ")])],1)],1)]:_vm._e(),(_vm.modalDetails.showDialog && _vm.modalDetails.name === 'DocumentModal')?_c('div',[_c('ModalLayout',{attrs:{"modal":_vm.modalDetails},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Upload Document ")]},proxy:true},{key:"body",fn:function(){return [_c('v-card',{on:{"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('v-card-text',[_c('v-row',{staticClass:"d-flex flex-column",attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-icon',{staticClass:"mt-5",attrs:{"size":"60"}},[_vm._v("mdi-cloud-upload")]),_c('p',[_vm._v("Drop your file(s) here, or click to select them.")])],1),(_vm.uploadedFiles.length > 0)?_c('v-virtual-scroll',{attrs:{"items":_vm.uploadedFiles,"height":"150","item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:item.name,staticClass:"pa-0"},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"ml-1 text--secondary"},[_vm._v(" "+_vm._s(item.size)+" bytes")])])],1),_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item.name)}}},[_c('v-icon',[_vm._v(" mdi-close-circle ")])],1)],1)],1),_c('v-divider')]}}],null,false,2079376717)}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.updateModalDetail({
                  showDialog: false,
                  name: 'DocumentModal',
                })}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"outlined":"","small":"","disabled":!_vm.uploadedFiles.length},on:{"click":function($event){$event.stopPropagation();return _vm.submit.apply(null, arguments)}}},[_vm._v(" Upload ")])],1)],1)]},proxy:true}],null,false,3276663484)})],1):_vm._e(),(
      _vm.modalDetails.showDialog && _vm.modalDetails.name === 'DeleteDocumentModal'
    )?_c('div',[_c('ModalLayout',{attrs:{"modal":_vm.modalDetails},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Delete Document ")]},proxy:true},{key:"body",fn:function(){return [_c('v-card-text',{staticClass:"px-0"},[_vm._v("Are you sure, you want to proceed with Delete? ")])]},proxy:true},{key:"footer",fn:function(){return [(_vm.modalDetails.errorMessage)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-text',{staticClass:"red--text caption"},[_vm._v(_vm._s(_vm.modalDetails.errorMessage))])],1):_vm._e(),_c('v-row',{staticClass:"d-flex flex-row-reverse",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"text":"","color":"error","small":""},on:{"click":_vm.deleteProjectDocument}},[_vm._v(" Delete ")]),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.updateModalDetail({
                showDialog: false,
                name: 'DeleteDocumentModal',
              })}}},[_vm._v(" Cancel ")])],1)]},proxy:true}],null,false,3238270101)})],1):_vm._e(),(!_vm.hidden)?_c('v-row',{staticClass:"mx-2 my-0"},[_c('v-col',{staticClass:"d-flex flex-row-reverse py-0"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.hidden = !_vm.hidden}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }