<template>
  <!-- Table Header -->
  <v-card class="py-2" tile>
    <v-row class="mx-2 align-center justify-center" no-gutters>
      <v-col cols="8">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Tables
        </v-card-title>
      </v-col>
      <v-col cols="4" class="d-flex flex-row-reverse">
        <v-btn v-if="!hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="updateModalDetail({ showDialog: true, name: 'TableModal' })"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Table Content -->
    <template v-if="hidden">
      <v-row class="mx-2" no-gutters v-if="Object.keys(tableList).length">
        <v-row
          class="mx-2 d-flex justify-center align-center"
          no-gutters
          v-for="(value, key) in tableList"
          :key="key"
          color="primary"
          link
          :to="`/projects/${projectId}/${key}`"
        >
          <v-col cols="10">
            <v-list-item-content class="mx-2">
              <v-list-item-title>{{ value.tableName }}</v-list-item-title>
              <v-list-item-subtitle>{{ key }}</v-list-item-subtitle>
              <v-card-text class="px-0 py-0">
                MetaData: sheetName - {{ value.metadata.sheetName }} | Range -
                {{ value.metadata.uploadRange }}
              </v-card-text>
            </v-list-item-content>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="grey"
              dark
              @click="
                updateModalDetail({
                  showDialog: true,
                  name: 'DeleteTableModal',
                  tableId: key,
                })
              "
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="mx-2" v-else>
        <v-list-item>
          <v-list-item-subtitle>No Table Found.</v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="text-right">
          <v-btn
            outlined
            @click="updateModalDetail({ showDialog: true, name: 'TableModal' })"
            small
          >
            Add Table
          </v-btn>
        </v-list-item>
      </v-row>
    </template>

    <!-- Add Table Modal -->
    <ModalLayout
      :modal="modalDetails"
      v-if="modalDetails.name === 'TableModal' && modalDetails.showDialog"
    >
      <template v-slot:header>Add New Table</template>
      <template v-slot:body>
        <v-text-field
          v-model="newTable.tableName"
          label="Table Name"
          required
          dense
        ></v-text-field>
        <v-text-field
          v-model="newTable.tableType"
          label="Table Type"
          required
          dense
        ></v-text-field>
        <v-select
          :items="frequency"
          label="Frequency"
          v-model="newTable.frequency"
          dense
        ></v-select>
        <v-text-field
          v-model="newTable.currency"
          label="Currency"
          required
          dense
        ></v-text-field>
        <v-card flat v-if="metadata && metadata.filename">
          <v-card-subtitle class="px-0 py-0">MetaData</v-card-subtitle>
          <v-card-text class="px-0 py-0"
            >FileName: {{ metadata.filename }}</v-card-text
          >
          <v-card-text class="px-0 py-0"
            >SheetName: {{ metadata.sheetName }}</v-card-text
          >
          <v-card-text class="px-0 py-0"
            >UploadRange: {{ metadata.uploadRange }}</v-card-text
          >
        </v-card>
        <v-col class="text-right">
          <v-btn @click="readTable" elevation="2" small>Read Table</v-btn>
        </v-col>
      </template>
      <template v-slot:footer>
        <v-row v-if="modalDetails.errorMessage" no-gutters>
          <v-card-text class="red--text caption">{{
            modalDetails.errorMessage
          }}</v-card-text>
        </v-row>
        <v-row no-gutters class="d-flex flex-row-reverse">
          <v-btn text @click="addTable" small> Add </v-btn>
          <v-btn
            text
            @click="
              updateModalDetail({ showDialog: false, name: 'TableModal' });
              resetTable();
            "
            small
          >
            Cancel
          </v-btn>
        </v-row>
      </template>
    </ModalLayout>

    <!-- Delete Table Modal -->

    <div
      v-if="modalDetails.showDialog && modalDetails.name === 'DeleteTableModal'"
    >
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Delete Table </template>
        <template v-slot:body>
          <v-card-text class="px-0"
            >Are you sure, you want to proceed with Delete?
          </v-card-text>
        </template>
        <template v-slot:footer>
          <v-row v-if="modalDetails.errorMessage" no-gutters>
            <v-card-text class="red--text caption">{{
              modalDetails.errorMessage
            }}</v-card-text>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5">
              <v-checkbox
                v-model="isDelete"
                label="Hard Delete"
                density="compact"
                class="ma-0 pa-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="7" class="d-flex flex-row-reverse">
              <v-btn text color="error" @click="deleteTables" small>
                Delete
              </v-btn>
              <v-btn
                text
                @click="
                  updateModalDetail({
                    showDialog: false,
                    name: 'DeleteTableModal',
                  })
                "
                small
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ModalLayout>
    </div>

    <!-- Table Footer -->
    <v-row v-if="hidden" class="mx-2 my-0">
      <v-col class="d-flex flex-row-reverse py-0">
        <v-btn icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Types } from "./../store/types";
import ModalLayout from "./ModalLayout.vue";

export default {
  name: "TablesComponent",
  components: {
    ModalLayout,
  },
  data: () => ({
    hidden: true,
    addTableModal: {
      showDialog: false,
      errorMessage: "",
    },
    metadata: {},
    newTable: {
      tableName: "",
      tableType: "",
      currency: "",
      frequency: "",
    },
    frequency: ["Year", "Quarter", "Month"],
    projectId: "",
    isDelete: false,
  }),
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("projectDetail", {
      modalDetails: Types.getters.GET_MODAL_DETAIL,
    }),
  },
  mounted() {
    this.projectId = this.$route.params.projectId;
  },
  methods: {
    ...mapActions("projectDetail", {
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
      uploadTable: Types.actions.DO_UPLOAD_TABLE,
      setToken: Types.actions.DO_TOKEN,
      deleteTable: Types.actions.DO_DELETE_TABLE,
    }),
    async addTable() {
      const tableData = await this.get_table();
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.uploadTable(tableData);
      this.resetTable();
    },
    get_table() {
      return window.Excel.run(async (context) => {
        // Get the selected range
        const selectedRange = context.workbook.getSelectedRange();
        selectedRange.load(["values", "address", "worksheet/name"]);

        // Sync the context to load the selected range properties
        await context.sync();

        // Extract the values and address from the selected range
        const values = selectedRange.values;
        const address = selectedRange.address
          .replace(selectedRange.worksheet.name, "")
          .replace(/[&#,+()$~%.'"*?!<>{}]/g, "");
        const sheetName = selectedRange.worksheet.name;
        // Modify the first cell to 'RowHeader'
        values[0][0] = "RowHeader";

        // Extract keys from the header row
        const keys = values[0];
        const table_headers = keys.map((item) =>
          item.replace(/[\s\r\n]/gm, ""),
        );
        // Process the table values to create a list of dictionaries
        const tableContent = values.slice(1).map((record) => {
          return keys.reduce((dict, key, index) => {
            dict[key.replace(/[\s\r\n]/gm, "")] = record[index];
            return dict;
          }, {});
        });

        // Extract the workbook name (filename)
        const workbook = context.workbook;
        workbook.load("name");
        await context.sync();
        const workbookName = workbook.name;

        // Prepare the result object with values and metadata
        const result = {
          table_name: this.newTable.tableName,
          table_content: tableContent,
          table_headers,
          metadata: {
            filename: workbookName,
            sheetName: sheetName,
            uploadRange: address,
          },
          project_id: this.projectId,
          statement_type: this.newTable.tableType,
        };
        this.contextTable = result;
        return result;
      }).catch((error) => {
        console.error("Error: " + error);
      });
    },
    async readTable() {
      this.metadata = {};
      const tableData = await this.get_table();
      this.metadata = tableData && tableData.metadata;
    },
    resetTable() {
      (this.newTable = {
        tableName: "",
        tableType: "",
        currency: "",
        frequency: "",
      }),
        (this.metadata = {});
    },
    async deleteTables() {
      let url = "";
      const { tableId } = this.modalDetails;
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      if (this.isDelete) {
        url = `/delete/${this.projectId}/contexttable?delTable=${tableId}`;
      } else {
        url = `/projects/${this.projectId}/tables?delTable=${tableId}`;
      }
      this.deleteTable({ url, projectId: this.projectId });
    },
  },
};
</script>
