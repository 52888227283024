export const Types = {
  getters: {
    GET_LOADING_STATUS: "GET_LOADING_STATUS",
    GET_PROJECT_LIST: "GET_PROJECT_LIST",
    GET_MODAL_DETAIL: "GET_MODAL_DETAIL",
    GET_PROJECT_DETAIL: "GET_PROJECT_DETAIL",
    GET_FILTERED_PROJECT: "GET_FILTERED_PROJECT",
    GET_TABLES: "GET_TABLES",
    GET_PROMPTS: "GET_PROMPTS",
    GET_QNA_RESULTS: "GET_QNA_RESULTS",
    GET_COMMENTARY_RESULTS: "GET_COMMENTARY_RESULTS",
    GET_PASTE_TABLE_CONTENT: "GET_PASTE_TABLE_CONTENT",
    GET_DOCUMENTS: "GET_DOCUMENTS",
    GET_SELECTED_ROW: "GET_SELECTED_ROW",
    GET_SETTINGS: "GET_SETTINGS",
    GET_VECTOR_STATUS: "GET_VECTOR_STATUS",
    GET_TABLE_STATUS: "GET_TABLE_STATUS",
    GET_SETTING_LOADING_STATUS: "GET_USER_LOADING_STATUS"
  },
  mutations: {
    SET_PROJECT_LIST: "SET_PROJECT_LIST",
    SET_TOKEN: "SET_TOKEN",
    SET_MODAL_DETAIL: "SET_MODAL_DETAIL",
    SET_PROJECT_DETAIL: "SET_PROJECT_DETAIL",
    SET_PROJECT_FILTER: "SET_PROJECT_FILTER",
    SET_TABLES: "SET_TABLES",
    SET_LOADING_STATUS: "SET_LOADING_STATUS",
    SET_PROMPTS: "SET_PROMPTS",
    SET_NEW_PROMPT: "SET_NEW_PROMPT",
    SET_COMMENTARY: "SET_COMMENTARY",
    SET_QNA: "SET_QNA",
    SET_SNACKBAR: "SET_SNACKBAR",
    SET_UPDATE_RESULTS: "SET_UPDATE_RESULTS",
    SET_DOCUMENTS: "SET_DOCUMENTS",
    SET_SELECTED_ROW: "SET_SELECTED_ROW",
    SET_VECTOR_STATUS: "SET_VECTOR_STATUS",
    SET_TABLE_STATUS: "SET_TABLE_STATUS",
    SET_DELETE_PROJECT: "SET_DELETE_PROJECT",
    SET_SETTING_LOADING_STATUS: "SET_USER_LOADING_STATUS",
    SET_SETTINGS: "SET_SETTINGS",
    SET_USERS: "SET_USERS"
  },
  actions: {
    DO_PROJECT_LIST: "DO_PROJECT_LIST",
    DO_CREATE_PROJECT: "CREATE_PROJECT",
    DO_TOKEN: "DO_TOKEN",
    DO_MODAL_DETAIL: "DO_MODAL_DETAIL",
    DO_PROJECT_DETAIL: "DO_PROJECT_DETAIL",
    DO_PROJECT_FILTER: "DO_PROJECT_FILTER",
    DO_ADD_USER: "DO_ADD_USER",
    DO_GET_TABLES: "DO_GET_TABLES",
    DO_GET_PROMPTS: "DO_GET_PROMPTS",
    DO_ADD_NEW_PROMPT: "DO_ADD_NEW_PROMPT",
    DO_UPLOAD_TABLE: "DO_UPLOAD_TABLE",
    DO_UPDATE_COMMENT: "DO_UPDATE_COMMENT",
    DO_UPDATE_QNA: "DO_UPDATE_QNA",
    DO_GET_RESULTS: "DO_GET_RESULTS",
    DO_UPDATE_SNACKBAR: "DO_UPDATE_SNACKBAR",
    DO_UPDATE_RESULTS: "DO_UPDATE_RESULTS",
    DO_ADD_NEW_SETTING: "DO_ADD_NEW_SETTING",
    DO_VECTORISE_SETTING: "DO_VECTORISE_SETTING",
    DO_GET_DOCUMENT: "DO_GET_DOCUMENT",
    DO_UPDATE_SELECTED_ROW: "DO_UPDATE_SELECTED_ROW",
    DO_UPDATE_TABLE_STATUS: "DO_UPDATE_TABLE_STATUS",
    DO_UPLOAD_DOCUMENT: "DO_UPLOAD_DOCUMENT",
    DO_DELETE_PROJECT: "DO_DELETE_PROJECT",
    DO_DELETE_SETTING: "DO_DELETE_SETTING",
    DO_DELETE_DOCUMENT: "DO_DELETE_DOCUMENT",
    DO_DELETE_USER: "DO_DELETE_USER",
    DO_DELETE_TABLE: "DO_DELETE_TABLE"
  },
};
