import { Types } from "./../../types";

export const getters = {
  [Types.getters.GET_LOADING_STATUS]: (state) => {
    return state.isLoading;
  },
  [Types.getters.GET_MODAL_DETAIL]: (state) => {
    return state.modal;
  },
  [Types.getters.GET_PROMPTS](state) {
    return state.prompts;
  },
};
