<template>
  <v-card class="py-2" tile>
    <v-row no-gutters class="mx-2 align-center justify-center">
      <v-col cols="8">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Users
        </v-card-title>
      </v-col>
      <v-col cols="4" class="d-flex flex-row-reverse">
        <!-- TODO : Need to remove field modal name in future-->
        <v-btn v-if="hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          @click="updateModalDetail({ showDialog: true, name: 'UserModal' })"
          icon
        >
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-simple-table v-if="!hidden" class="mx-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">User ID</th>
            <th class="text-left">Roles</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in projectDetail.users" :key="user.userId">
            <td>{{ user.userId }}</td>
            <td>{{ getUserRole(user.role) }}</td>
            <td>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.stop>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="
                      updateModalDetail({
                        showDialog: true,
                        name: 'DeleteUserModal',
                        userId: user.userId,
                      })
                    "
                  >
                    <v-list-item-icon color="grey" dark>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Delete User</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- Add User -->
    <div v-if="modalDetails.showDialog && modalDetails.name === 'UserModal'">
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Add User </template>
        <template v-slot:body>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="User ID"
                type="email"
                v-model="addUser"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="Object.keys(availableRoles)"
                density="compact"
                label="Roles"
                class="my-0"
                v-model="addRoles"
              ></v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row v-if="modalDetails.errorMessage" no-gutters>
            <v-card-text class="red--text caption">{{
              modalDetails.errorMessage
            }}</v-card-text>
          </v-row>
          <v-row no-gutters class="d-flex flex-row-reverse">
            <v-btn text @click="addUsers" small> Add </v-btn>
            <v-btn
              text
              @click="
                updateModalDetail({ showDialog: false, name: 'UserModal' });
                resetUser();
              "
              small
            >
              Cancel
            </v-btn>
          </v-row>
        </template>
      </ModalLayout>
    </div>

    <!-- Delete User -->
    <div
      v-if="modalDetails.showDialog && modalDetails.name === 'DeleteUserModal'"
    >
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Delete User </template>
        <template v-slot:body>
          <v-row>
            <v-card-text
              >Are you sure, you want to proceed with Delete?
            </v-card-text>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row v-if="modalDetails.errorMessage" no-gutters>
            <v-card-text class="red--text caption">{{
              modalDetails.errorMessage
            }}</v-card-text>
          </v-row>
          <v-row no-gutters class="d-flex flex-row-reverse">
            <v-btn
              text
              color="error"
              @click="() => deleteUsers(modalDetails.document)"
              small
            >
              Delete
            </v-btn>
            <v-btn
              text
              @click="
                updateModalDetail({
                  showDialog: false,
                  name: 'DeleteUserModal',
                })
              "
              small
            >
              Cancel
            </v-btn>
          </v-row>
        </template>
      </ModalLayout>
    </div>

    <v-row v-if="!hidden" class="mx-2 my-0" no-gutters>
      <v-col class="d-flex flex-row-reverse">
        <v-btn icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ModalLayout from "./ModalLayout.vue";
import { mapActions, mapGetters } from "vuex";
import { Types } from "./../store/types";

export default {
  name: "UserComponent",
  components: {
    ModalLayout,
  },
  data: () => ({
    hidden: true,
    search: "",
    availableRoles: {
      Admin: ["admin", "read", "write"],
      Write: ["read", "write"],
      Read: ["read"],
    },
    addUser: "",
    addRoles: [],
    projectId: "",
  }),
  computed: {
    ...mapGetters("projectDetail", {
      projectDetail: Types.getters.GET_PROJECT_DETAIL,
      modalDetails: Types.getters.GET_MODAL_DETAIL,
    }),
  },
  async mounted() {
    this.projectId = this.$route.params.projectId;
  },
  methods: {
    ...mapActions("projectDetail", {
      setToken: Types.actions.DO_TOKEN,
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
      addNewUser: Types.actions.DO_ADD_USER,
      deleteUser: Types.actions.DO_DELETE_USER,
    }),
    async addUsers() {
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.addNewUser({
        requestBody: {
          addUser: this.addUser,
          addRoles: this.availableRoles[this.addRoles],
        },
        projectId: this.projectId,
      });
      this.resetUser();
    },
    getUserRole(userRole) {
      let role = "";
      Object.keys(this.availableRoles).forEach((item) => {
        const result =
          !role &&
          this.availableRoles[item].every((value) => userRole.includes(value));
        if (result) {
          role = item;
        }
      });
      return role;
    },
    resetUser() {
      this.addUser = "";
      this.addRoles = [];
    },
    async deleteUsers() {
      const { userId } = this.modalDetails;
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.deleteUser({ projectId: this.projectId, userId });
    },
  },
};
</script>
