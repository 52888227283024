import Vue from "vue";
import Vuex from "vuex";
import projectList from "./modules/projectList";
import projectDetail from "./modules/projectDetail";
import promptDetail from "./modules/promptDetail";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: () => ({
  }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    projectList,
    projectDetail,
    promptDetail,
  },
  plugins: [vuexLocal.plugin],
});
