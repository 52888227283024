<template>
  <div>
    <!-- Toolbar with Actions -->
    <v-toolbar dense flat>
      <v-toolbar-title>Prompt Detail</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        dark
        @click="updateModalDetail({ showDialog: true }); resetPrompt()"
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!-- Add Prompt Modal-->
      <div v-if="modalDetails.showDialog">
        <ModalLayout :modal="modalDetails">
          <template v-slot:header> Add New Prompt </template>
          <template v-slot:body>
            <v-text-field
              v-model="newPrompt.promptName"
              label="Prompt Name"
            ></v-text-field>
            <v-select
              v-model="newPrompt.promptType"
              :items="promptType"
              label="Prompt Type"
            ></v-select>
            <v-textarea
              v-model="newPrompt.promptBody"
              :label="`${newPrompt.prompt_type || ''} Prompt Body`"
              auto-grow
              rows="1"
              row-height="15"
            ></v-textarea>
            <v-select
              v-model="newPrompt.promptMethod"
              :items="promptMethod"
              label="Prompt Method"
            ></v-select>
            <v-checkbox
              v-if="admin"
              v-model="newPrompt.promptAdmin"
              label="Admin"
            ></v-checkbox>
          </template>
          <template v-slot:footer>
            <v-btn
              text
              @click="updateModalDetail({ showDialog: false }); resetPrompt()"
              style="font-size: 0.6rem"
            >
              Cancel
            </v-btn>
            <v-btn text @click="createNewPrompt" style="font-size: 0.6rem">
              Add
            </v-btn>
          </template>
        </ModalLayout>
      </div>
      <v-btn color="secondary" dark @click="goBack" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-toolbar>
    <LoaderComponent v-if="isLoading" />
    <template v-else>
      <v-expansion-panels>
        <v-expansion-panel v-for="prompt in prompts" :key="prompt.id">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      prompt.promptName
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      prompt.id.slice(0, 8)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6" class="text--secondary"
                ><v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text--secondary">{{
                      prompt.promptType
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text--secondary">{{
                      prompt.promptMethod
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ prompt.promptBody }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import ModalLayout from "../components/ModalLayout.vue";
import { mapActions, mapGetters } from "vuex";
import LoaderComponent from "../components/LoaderComponent.vue";
import { Types } from "./../store/types";

export default {
  name: "PromptDetailsPage",
  components: {
    LoaderComponent,
    ModalLayout,
  },
  props: {
    admin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      promptType: ["user", "system"],
      promptMethod: ["summary", "commmentary", "qna"],
      newPrompt: {
        promptType: "",
        promptBody: "",
        promptName: "",
        promptMethod: "",
        promptAdmin: false,
      },
      selectedCardIndex: -1,
    };
  },
  computed: {
    ...mapGetters("promptDetail", {
      prompts: Types.getters.GET_PROMPTS,
      isLoading: Types.getters.GET_LOADING_STATUS,
      modalDetails: Types.getters.GET_MODAL_DETAIL,
    }),
  },
  async mounted() {
    await this.$msal.acquireToken();
    this.setToken(this.$msal.data.accessToken);
    this.getPrompts();
  },
  methods: {
    ...mapActions("promptDetail", {
      setToken: Types.actions.DO_TOKEN,
      addPrompt: Types.actions.DO_ADD_NEW_PROMPT,
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
      getPrompts: Types.actions.DO_GET_PROMPTS,
    }),
    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    async createNewPrompt() {
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.addPrompt(this.newPrompt);
    },
    resetPrompt() {
      this.newPrompt = {
        promptType: "",
        promptBody: "",
        promptName: "",
        promptMethod: "",
        promptAdmin: false,
      }
    }
  },
};
</script>
