export const state = () => ({
  isLoading: false,
  projectList: [],
  filteredProjectList: [],
  snackbar:'',
  modal: {
    showDialog: false,
    errorMessage: "",
  },
});
