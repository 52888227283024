import { HTTP } from "./../../../functions/http-common";
import { Types } from "./../../types";

export const actions = {
  [Types.actions.DO_TOKEN]: ({ commit }, payload) => {
    commit(Types.mutations.SET_TOKEN, payload);
  },
  [Types.actions.DO_MODAL_DETAIL]: ({ commit }, payload) => {
    commit(Types.mutations.SET_MODAL_DETAIL, payload);
  },
  async [Types.actions.DO_GET_PROMPTS]({ commit, state }) {
    commit(Types.mutations.SET_LOADING_STATUS, true);
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    HTTP.post("/retrieve/prompts", {}, config)
      .then((response) => {
        commit(Types.mutations.SET_PROMPTS, response.data);
        commit(Types.mutations.SET_LOADING_STATUS, false);
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, false);
        console.log(error);
      });
  },

  [Types.actions.DO_ADD_NEW_PROMPT]: async (
    { dispatch, commit, state },
    payload,
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    };
    commit(Types.mutations.SET_LOADING_STATUS, true);
    await HTTP.post("/prompt", payload, config)
      .then((response) => {
        console.log("sucessfully added prompt", response);
        commit(Types.mutations.SET_MODAL_DETAIL, { showDialog: false });
        dispatch(Types.actions.DO_GET_PROMPTS);
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        commit(Types.mutations.SET_LOADING_STATUS, false);
        console.log(error);
      });
  },
};
