<template>
  <v-card class="py-2" tile>
    <v-row no-gutters class="mx-2 align-center justify-center">
      <v-col cols="6">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Settings
        </v-card-title>
      </v-col>
      <v-col cols="6" class="d-flex flex-row-reverse">
        <v-btn v-if="hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          @click="updateModalDetail({ showDialog: true, name: 'SettingModal' })"
          icon
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn icon @click="refreshTable" v-if="!isLoading">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-progress-circular v-else indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-row class="mx-5 my-2" v-if="!hidden">
      <v-expansion-panels>
        <v-expansion-panel v-for="(setting, key) in vectorStatus" :key="key">
          <v-expansion-panel-header class="py-0">
            <v-row class="align-center justify-center">
              <v-col cols="8" class="py-0 px-0">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ setting.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      setting.vectorstoreId.slice(0, 8)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="4" class="d-flex">
                <v-btn
                  color="grey"
                  dark
                  @click="
                    updateModalDetail({
                      showDialog: true,
                      name: 'DeleteSettingModal',
                      vectorStoreId: setting.vectorstoreId,
                    })
                  "
                  icon
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-progress-circular
                  v-if="setting.status === 'running'"
                  indeterminate
                ></v-progress-circular>
                <v-btn
                  color="error"
                  icon
                  v-else-if="setting.status === 'failed'"
                >
                  <v-icon>mdi-alert-circle</v-icon>
                </v-btn>
                <v-btn icon v-else-if="setting.status === 'finished'">
                  <v-icon>mdi-checkbox-marked-circle</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-else-if="
                    (!setting.status && isQueued) || setting.status === 'queued'
                  "
                >
                  <v-icon>mdi-tray-full</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card flat>
              <v-card-text class="text-caption py-0">
                Embedder Chunk size: {{ setting.embedderChunksize }}
              </v-card-text>
              <v-card-text class="text-caption py-0">
                Embedder Model: {{ setting.embedderModel }}
              </v-card-text>
              <v-card-text class="text-caption py-0">
                Embedder Max Retries: {{ setting.embedderMaxRetries }}
              </v-card-text>
              <v-card-text class="text-caption py-0">
                Tokenizer Encoding: {{ setting.tokenizerEncoding }}
              </v-card-text>
              <v-card-text class="text-caption py-0">
                Tokenizer Encoding Model: {{ setting.tokenizerEncodingModel }}
              </v-card-text>
              <v-card-text class="text-caption py-0">
                Chunk Size: {{ setting.chunkSize }}
              </v-card-text>
              <v-card-text class="text-caption py-0">
                Chunk Overlap: {{ setting.chunkOverlap }}
              </v-card-text>
              <v-card-text class="d-flex flex-row-reverse">
                <v-btn
                  v-if="setting.status === 'new' || setting.status === 'failed'"
                  :disabled="!Object.keys(documents).length"
                  elevation="2"
                  small
                  @click="submiteVectorise(setting.vectorstoreId)"
                >
                  Vectorise
                </v-btn>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <div v-if="modalDetails.showDialog && modalDetails.name === 'SettingModal'">
      <ModalLayout :modal="modalDetails">
        <template v-slot:header> Add New Settings </template>
        <template v-slot:body>
          <v-text-field v-model="settings.name" label="Name"></v-text-field>
          <v-text-field
            v-model="settings.embedderChunksize"
            label="Embedder Chunk Size"
            type="number"
          ></v-text-field>
          <v-select
            v-model="settings.embedderModel"
            :items="embedderModel"
            label="Embedder Model"
          ></v-select>
          <v-text-field
            v-model="settings.embedderMaxRetries"
            label="Embedder Max Retries"
            type="number"
          ></v-text-field>
          <v-select
            v-model="settings.tokenizerEncoding"
            :items="tokenizerEncoding"
            label="Tokenizer Encoding"
          ></v-select>
          <v-select
            v-model="settings.tokenizerEncodingModel"
            :items="tokenizerEncodingModel"
            label="Tokenizer Encoding Model"
          ></v-select>
          <v-text-field
            v-model="settings.chunkSize"
            label="ChunkSize"
            type="number"
          ></v-text-field>
          <v-text-field
            v-model="settings.chunkOverlap"
            label="Chunk Overlap"
            type="number"
          ></v-text-field>
        </template>
        <template v-slot:footer>
          <v-row v-if="modalDetails.errorMessage" no-gutters>
            <v-card-text class="red--text caption">{{
              modalDetails.errorMessage
            }}</v-card-text>
          </v-row>
          <v-row no-gutters class="d-flex flex-row-reverse">
            <v-btn text @click="createNewSettings" small> Add </v-btn>
            <v-btn
              text
              @click="
                updateModalDetail({ showDialog: false, name: 'SettingModal' });
                resetSettings();
              "
              small
            >
              Cancel
            </v-btn>
          </v-row>
        </template>
      </ModalLayout>
    </div>
    <div
      v-if="
        modalDetails.showDialog && modalDetails.name === 'DeleteSettingModal'
      "
    >
      <ModalLayout :modal="modalDetails"
        ><template v-slot:header> Delete Setting </template>
        <template v-slot:body>
          <v-card-text class="px-0"
            >Are you sure, you want to proceed with Delete?
          </v-card-text>
        </template>
        <template v-slot:footer>
          <v-row v-if="modalDetails.errorMessage" no-gutters>
            <v-card-text class="red--text caption">{{
              modalDetails.errorMessage
            }}</v-card-text>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5">
              <v-checkbox
                v-model="isDelete"
                label="Hard Delete"
                density="compact"
                class="ma-0 pa-0"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="7" class="d-flex flex-row-reverse">
              <v-btn text color="error" @click="deleteSettings" small>
                Delete
              </v-btn>
              <v-btn
                text
                @click="
                  updateModalDetail({
                    showDialog: false,
                    name: 'DeleteSettingModal',
                  })
                "
                small
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ModalLayout>
    </div>
    <v-row class="mx-2 my-0" no-gutters v-if="!hidden">
      <v-col class="d-flex flex-row-reverse">
        <v-btn icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Types } from "../store/types";
import ModalLayout from "./ModalLayout.vue";

export default {
  name: "ProjectSettings",
  components: {
    ModalLayout,
  },
  data: () => ({
    hidden: true,
    embedderModel: ["text-embedding-ada-002"],
    tokenizerEncoding: ["cl100k_base"],
    tokenizerEncodingModel: ["gpt-4"],
    settings: {
      name: "",
      embedderChunksize: "",
      embedderModel: "",
      embedderMaxRetries: "",
      tokenizerEncoding: "",
      tokenizerEncodingModel: "",
      chunkSize: "",
      chunkOverlap: "",
    },
    isQueued: false,
    projectId: "",
    isDelete: false,
  }),
  computed: {
    ...mapGetters("projectDetail", {
      projectDetail: Types.getters.GET_PROJECT_DETAIL,
      modalDetails: Types.getters.GET_MODAL_DETAIL,
      documentList: Types.getters.GET_DOCUMENTS,
      vectorStatus: Types.getters.GET_VECTOR_STATUS,
      isLoading: Types.getters.GET_SETTING_LOADING_STATUS,
      documents: Types.getters.GET_DOCUMENTS,
    }),
  },
  async mounted() {
    this.projectId = this.$route.params.projectId;
  },
  methods: {
    ...mapActions("projectDetail", {
      setToken: Types.actions.DO_TOKEN,
      updateModalDetail: Types.actions.DO_MODAL_DETAIL,
      addSetting: Types.actions.DO_ADD_NEW_SETTING,
      addVectorise: Types.actions.DO_VECTORISE_SETTING,
      getProjectDetails: Types.actions.DO_PROJECT_DETAIL,
      deleteSetting: Types.actions.DO_DELETE_SETTING,
    }),
    async createNewSettings() {
      const projectId = this.$route.params.projectId;
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.addSetting({
        requestBody: { vs_settings: this.settings },
        projectId,
      });
      this.resetSettings();
    },
    async submiteVectorise(vectorstoreId) {
      const payload = {
        projectId: this.$route.params.projectId,
        vectorstore: {
          vectorstoreId,
        },
      };
      this.isQueued = true;
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.addVectorise(payload);
    },
    resetSettings() {
      this.settings = {
        name: "",
        embedderChunksize: "",
        embedderModel: "",
        embedderMaxRetries: "",
        tokenizerEncoding: "",
        tokenizerEncodingModel: "",
        chunkSize: "",
        chunkOverlap: "",
      };
    },
    refreshTable() {
      const projectId = this.$route.params.projectId;
      const params = {
        queueMethod: "vectorise",
      };
      this.getProjectDetails({ params, projectId, loader: false });
    },
    async deleteSettings() {
      let url = "";
      const { vectorStoreId } = this.modalDetails;
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      if (this.isDelete) {
        url = `/delete/${this.projectId}/vectorstore?delVectorstore=${vectorStoreId}`;
      } else {
        url = `/projects/${this.projectId}/settings?delVectorstore=${vectorStoreId}`;
      }
      this.deleteSetting({ url, vectorStoreId });
    },
  },
};
</script>
