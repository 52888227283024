<template>
  <div>
    <v-toolbar flat>
      <v-btn color="secondary" dark @click="goBack" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="py-2">
        {{ tableList[tableId]?.tableName }} <br />
        <span class="text-caption">{{ tableId }}</span></v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <LoaderComponent v-if="isLoading" />
    <template v-else>
      <v-card tile class="elevation-0">
        <v-row no-gutters class="my-2 align-center justify-center">
          <v-col cols="10">
            <v-card-title class="py-3">{{ tableList[tableId]?.tableName }}</v-card-title>
            <v-card-subtitle class="py-0">Databook Table</v-card-subtitle>
          </v-col>
          <v-col cols="2">
            <v-btn icon @click="refreshTable">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-card-text
          v-if="
            !tableList[tableId]?.tableContent ||
            !tableList[tableId]?.tableContent?.length
          "
        >
          No Result Found
        </v-card-text>
        <div v-else>
          <v-card-actions class="px-4 py-0">
            <v-btn outlined @click="pasteTableIntoExcel" small>Paste</v-btn>
            <v-spacer></v-spacer>
            <v-select
              class="px-5"
              v-model="resultType"
              :items="ResultList"
              label="Result"
            ></v-select>
            <v-btn outlined @click="updateResult(resultType)" small>Submit</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-data-table
              v-model="selectedRowList"
              :headers="headers"
              item-key="rowId"
              :items="tableList[tableId]?.tableContent"
              :items-per-page="10"
              show-select
              show-expand
              :loading="isLoading"
              loading-text="Loading... Please wait"
              :single-expand="true"
              dense
              class="elevation-0"
              :mobile-breakpoint="0"
              @toggle-select-all="selectAll"
            >
              <template v-slot:item="props">
                <tr class="my-0 py-0">
                  <td
                    @click="props.expand(!props.isExpanded)"
                  >
                    <v-icon v-if="props.isExpanded">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </td>
                  <td style="position: relative">
                    <v-checkbox
                      :input-value="props.isSelected"
                      @change="props.select($event)"
                      style="position: absolute; top: 7px"
                      class="pa-0 ma-0"
                    ></v-checkbox>
                  </td>
                  <td>{{ props.item.RowHeader }}</td>
                  <td v-if="tableStatus[props.item.rowId]">
                    <v-progress-circular
                      v-if="tableStatus[props.item.rowId].running === 1"
                      indeterminate
                    ></v-progress-circular>
                    <v-badge
                      :content="tableStatus[props.item.rowId].running"
                      color="error"
                      v-if="tableStatus[props.item.rowId].running > 1"
                    >
                      <v-progress-circular indeterminate></v-progress-circular>
                    </v-badge>
                    <v-btn
                      icon
                      v-else-if="tableStatus[props.item.rowId].queued === 1"
                    >
                      <v-icon>mdi-tray-full</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      v-else-if="tableStatus[props.item.rowId].queued > 1"
                    >
                      <v-badge
                        :content="tableStatus[props.item.rowId].queued"
                        color="error"
                      >
                        <v-icon>mdi-tray-full</v-icon>
                      </v-badge>
                    </v-btn>
                    <v-btn
                      color="error"
                      icon
                      v-else-if="tableStatus[props.item.rowId].failed === 1"
                    >
                      <v-icon>mdi-alert-circle</v-icon>
                    </v-btn>
                    <v-btn
                      color="error"
                      icon
                      v-else-if="tableStatus[props.item.rowId].failed > 1"
                    >
                      <v-badge
                        :content="tableStatus[props.item.rowId].failed"
                        color="error"
                      >
                        <v-icon>mdi-alert-circle</v-icon>
                      </v-badge>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td class="ma-0 pa-0" :colspan="headers.length">
                  <v-tabs height="30" v-model="tab">
                    <v-tab>Settings</v-tab>
                    <v-tab>Q&A</v-tab>
                    <v-tab>Comments</v-tab>
                    <v-tab-item v-if="settings[item.rowId]">
                      <v-row>
                        <v-card-text class="text-caption"
                          >RowId: {{ item.rowId }}</v-card-text
                        >
                      </v-row>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="6" class="d-flex">
                            <v-select
                              :items="modelType"
                              label="Model Type"
                              v-model="
                                settings[item.rowId].pipelineSettings.model_type
                              "
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="6" class="d-flex">
                            <v-text-field
                              label="Temperature"
                              :rules="rules"
                              v-model="
                                settings[item.rowId].pipelineSettings
                                  .temperature
                              "
                              dense
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="6" class="d-flex">
                            <v-select
                              :items="
                                prompts.filter(
                                  (item) =>
                                    item.promptType === 'user' &&
                                    item.promptMethod === 'commentary',
                                )
                              "
                              label="User Commentary Prompt ID"
                              v-model="
                                settings[item.rowId].promptIds
                                  .userCommentaryPromptId
                              "
                              item-value="id"
                              item-text="promptName"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="6" class="d-flex">
                            <v-select
                              :items="
                                prompts.filter(
                                  (item) =>
                                    item.promptType === 'system' &&
                                    item.promptMethod === 'commentary',
                                )
                              "
                              label="System Commentary Prompt ID"
                              v-model="
                                settings[item.rowId].promptIds
                                  .systemCommentaryPromptId
                              "
                              item-value="id"
                              item-text="promptName"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="6" class="d-flex">
                            <v-select
                              :items="
                                prompts.filter(
                                  (item) =>
                                    item.promptType === 'user' &&
                                    item.promptMethod === 'qna',
                                )
                              "
                              label="User QnA Prompt ID"
                              v-model="
                                settings[item.rowId].promptIds.userQnAPromptId
                              "
                              item-value="id"
                              item-text="promptName"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="6" class="d-flex">
                            <v-select
                              :items="
                                prompts.filter(
                                  (item) =>
                                    item.promptType === 'system' &&
                                    item.promptMethod === 'qna',
                                )
                              "
                              label="System QnA Prompt ID"
                              v-model="
                                settings[item.rowId].promptIds.systemQnAPromptId
                              "
                              item-value="id"
                              item-text="promptName"
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <v-combobox
                              v-model="settings[item.rowId].contextFlag"
                              :items="contextFlag"
                              label="Additional Context"
                              multiple
                              outlined
                              dense
                              small-chips
                              @change="updateContextFlag(item)"
                            >
                            </v-combobox>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container
                        fluid
                        v-if="isVectorStoreContext[item.rowId]"
                      >
                        <v-row>
                          <v-col cols="12" class="d-flex">
                            <v-select
                              :items="projectDetail.vectorstores"
                              label="Vector Stores"
                              item-value="vectorstoreId"
                              item-text="name"
                              v-model="
                                settings[item.rowId].vectorstore.vectorstoreId
                              "
                              dense
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-title class="text-body-1">
                          <ResultComponent
                            :rowId="item.rowId"
                            :list="qnaResults"
                          />
                        </v-card-title>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-title class="text-body-1"
                          ><ResultComponent
                            :rowId="item.rowId"
                            :list="commentaryResults"
                        /></v-card-title>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-subtitle>Q&A</v-card-subtitle>
          <v-card-text
            v-if="!tableList[tableId].qna || !tableList[tableId].qna.length"
          >
            No QnA Uploaded
          </v-card-text>
          <v-card-text v-else>
            <v-data-table
              :headers="headers"
              item-key="rowId"
              :items="tableList[tableId].qna"
              :items-per-page="10"
              show-select
              show-expand
              :loading="isLoading"
              loading-text="Loading... Please wait"
              dense
              class="elevation-0"
              :mobile-breakpoint="0"
            ></v-data-table>
          </v-card-text>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Types } from "./../store/types";
import ResultComponent from "../components/ResultComponent.vue";
import LoaderComponent from "../components/LoaderComponent.vue";

export default {
  name: "TablePage",
  components: {
    ResultComponent,
    LoaderComponent,
  },
  data: () => ({
    tableId: "",
    projectId: "",
    tab: null,
    headers: [
      { text: "Row", value: "RowHeader", sortable: false, align: "left" },
    ],
    modelType: ["gpt-4"],
    ResultList: ["QnA", "Commentary"],
    resultType: "",
    contextFlag: [
      "fullTable",
      "qnaContext",
      "relatedContext",
      "vectorstoreContext",
    ],
    isVectorStoreContext: {},
    rules: [
      (value) => {
        if (value >= 0 && value <= 1) return true;

        return "Please enter the value within range from 0 to 1";
      },
    ],
  }),
  computed: {
    ...mapGetters("projectDetail", {
      tableList: Types.getters.GET_TABLES,
      projectDetail: Types.getters.GET_PROJECT_DETAIL,
      commentaryResults: Types.getters.GET_COMMENTARY_RESULTS,
      qnaResults: Types.getters.GET_QNA_RESULTS,
      pasteTable: Types.getters.GET_PASTE_TABLE_CONTENT,
      isLoading: Types.getters.GET_LOADING_STATUS,
      selectedRow: Types.getters.GET_SELECTED_ROW,
      settings: Types.getters.GET_SETTINGS,
      tableStatus: Types.getters.GET_TABLE_STATUS,
    }),
    ...mapGetters("promptDetail", {
      prompts: Types.getters.GET_PROMPTS,
    }),
    selectedRowList: {
      get: function () {
        return this.selectedRow;
      },
      set: function (value) {
        this.updateSelectedRow(value);
      },
    },
  },
  async mounted() {
    this.tableId = this.$route.params.tableId;
    this.projectId = this.$route.params.projectId;
    this.setSnackbar(this.$root.snackbar);
    await this.$msal.acquireToken();
    this.setToken(this.$msal.data.accessToken);
    this.getTableDetails({ projectId: this.projectId, tableId: this.tableId });
    this.getQnAResults();
    this.getCommentaryResults();
    this.refreshStatus();
  },
  methods: {
    ...mapActions("projectDetail", {
      getTableDetails: Types.actions.DO_GET_TABLES,
      addCommentary: Types.actions.DO_UPDATE_COMMENT,
      addQnA: Types.actions.DO_UPDATE_QNA,
      setToken: Types.actions.DO_TOKEN,
      getResults: Types.actions.DO_GET_RESULTS,
      setSnackbar: Types.actions.DO_UPDATE_SNACKBAR,
      updateSheet: Types.actions.DO_UPDATE_RESULTS,
      updateSelectedRow: Types.actions.DO_UPDATE_SELECTED_ROW,
      getTableStatus: Types.actions.DO_UPDATE_TABLE_STATUS,
    }),
    goBack() {
      this.$router.go(-1);
    },
    async updateResult(type) {
      let selectedRowId = {
        projectId: this.projectId,
        tableId: this.tableId,
      };
      let rowIds = [];
      let contextFlag = {};
      let currentRow = {};
      /* eslint-disable */
      this.selectedRowList.forEach((item) => {
        currentRow = this.settings[item.rowId];
        this.contextFlag.forEach((subItem) => {
          contextFlag = {
            ...contextFlag,
            [subItem]: this.settings[item.rowId].contextFlag.includes(subItem),
          };
        });
        if (!this.isVectorStoreContext[item.rowId])
          delete currentRow.vectorstore;
        let promptIds = {
          userPromptId:
            type === "QnA"
              ? this.settings[item.rowId].promptIds.userQnAPromptId
              : this.settings[item.rowId].promptIds.userCommentaryPromptId,
          systemPromptId:
            type === "QnA"
              ? this.settings[item.rowId].promptIds.systemQnAPromptId
              : this.settings[item.rowId].promptIds.systemCommentaryPromptId,
        };
        rowIds.push({
          ...currentRow,
          contextFlag,
          promptIds,
        });
      });
      selectedRowId = { ...selectedRowId, rowIds };
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      if (type === "QnA") this.addQnA(selectedRowId);
      else this.addCommentary(selectedRowId);
      this.refreshStatus();
    },
    getQnAResults() {
      const params = {
        queueMethod: "qna",
        tableId: this.tableId,
      };
      this.getResults({ params, projectId: this.projectId });
    },
    getCommentaryResults() {
      const params = {
        queueMethod: "lineCommentary",
        tableId: this.tableId,
      };
      this.getResults({ params, projectId: this.projectId });
    },
    updateContextFlag(item) {
      this.isVectorStoreContext = {
        [item.rowId]:
          this.settings[item.rowId].contextFlag.includes("vectorstoreContext"),
      };
    },
    pasteTableIntoExcel() {
      const tableContent = this.tableList[this.tableId].tableContent;
      let tableData = this.tableList[this.tableId];
      this.updateSheet({ tableContent });
      tableData = { ...tableData, tableContent: this.pasteTable };
      return Excel.run(async (context) => {
        // Create a new worksheet
        const sheet = context.workbook.worksheets.add(tableData.tableName);

        // Convert tableContent into a format suitable for Excel
        let headers = [...tableData.tableHeaders, "QnA", "Commentary"];
        let data = tableData.tableContent.map((row) =>
          headers.map((header) => row[header]),
        );

        // Insert headers at the top of the data array
        data.unshift(headers);

        // Determine the range to paste data
        let rowCount = data.length;
        let columnCount = headers.length;
        let pasteRange = `B2:${this.numToLetters(columnCount + 1)}${
          rowCount + 1
        }`;

        // Set values in the determined range
        let range = sheet.getRange(pasteRange);
        range.values = data;

        // Optional: Apply styling/formatting here

        // Load the name of the new sheet to read it back later
        sheet.load("name");

        // Sync to apply changes
        await context.sync();

        console.log(`Table pasted into new sheet: ${sheet.name}`);
      }).catch((error) => {
        console.error("Error: " + error);
      });
    },

    // Helper function to convert column index to Excel letters (e.g., 1 -> A, 28 -> AB)
    numToLetters(num) {
      let letters = "";
      while (num > 0) {
        let remainder = (num - 1) % 26;
        letters = String.fromCharCode(65 + remainder) + letters;
        num = Math.floor((num - remainder) / 26);
      }
      return letters;
    },
    async refreshTable() {
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.getQnAResults();
      this.getCommentaryResults();
      this.refreshStatus();
    },
    selectAll(event) {
      if (event.value)
        this.updateSelectedRow(this.tableList[this.tableId].tableContent);
      else this.updateSelectedRow([]);
    },
    async refreshStatus() {
      const payload = {
        projectId: this.projectId,
        tableId: this.tableId,
      };
      await this.$msal.acquireToken();
      this.setToken(this.$msal.data.accessToken);
      this.getTableStatus(payload);
    },
  },
};
</script>
