import { Types } from "./../../types";

export const mutations = {
  [Types.mutations.SET_LOADING_STATUS]: (state, payload) => {
    state.isLoading = payload;
  },
  [Types.mutations.SET_TOKEN]: (state, payload) => {
    state.token = payload;
  },
  [Types.mutations.SET_PROJECT_DETAIL]: (state, payload) => {
    state.projectDetail = { ...payload };
  },
  [Types.mutations.SET_DELETE_PROJECT]: (state, payload) => {
    state.projectDetail = payload;
  },
  [Types.mutations.SET_MODAL_DETAIL]: (state, payload) => {
    state.modal = { ...state.modal, ...payload };
  },
  [Types.mutations.SET_TABLES]: (state, payload) => {
    const { data, tableId } = payload;
    if (data.length) {
      let temp = [];
      const list = data.reduce((obj, item) => {
        if (obj[item.id] === undefined) {
          obj[item.id] = [];
        }
        item.tableContent.forEach((subItem) => {
          temp = [
            ...temp,
            {
              tableName: item.tableName,
              metadata: item.metadata,
              RowHeader: subItem.RowHeader,
              rowId: subItem.rowId,
            },
          ];
        });
        obj[item.id] = item;
        return obj;
      }, {});
      state.tables = { ...list };
      if (tableId) {
        state.selectedTableRow = state.tables[tableId].tableContent;
        state.tables[tableId].tableContent.forEach((item) => {
          state.settings[item.rowId] = {
            rowId: item.rowId,
            pipelineSettings: {
              model_type: "gpt-4",
              temperature: 0.2,
            },
            vectorstore: {
              vectorstoreId: "d1c0d972-e6b4-44ed-bce2-5c5f52407779",
            },
            contextFlag: ["fullTable"],
            promptIds: {
              userCommentaryPromptId: "ac4be48a-5cfb-45e6-a51a-3a81a40a90f9",
              systemCommentaryPromptId: "d2edb205-9ced-4da2-b092-9d8c8654c4ad",
              userQnAPromptId: "3c36dc96-20d3-44e1-bedf-0a22d1194f68",
              systemQnAPromptId: "8d7be7b9-451b-48d7-97a1-ad44200996cb",
            },
          };
        });
      }
    } else {
      state.tables = {};
      state.selectedTableRow = [];
    }
  },
  [Types.mutations.SET_QNA]: (state, payload) => {
    state.QnAResults = payload;
  },
  [Types.mutations.SET_USERS]: (state, payload) => {
    const updatedUsers = state.projectDetail.users.filter(item => item.userId !== payload)
    state.projectDetail = {...state.projectDetail, users: updatedUsers }
  },
  [Types.mutations.SET_COMMENTARY]: (state, payload) => {
    state.commentaryResults = payload;
  },
  [Types.mutations.SET_SNACKBAR]: (state, payload) => {
    state.snackbar = payload;
  },
  [Types.mutations.SET_VECTOR_STATUS]: (state, payload) => {
    let VectorStoreStatusList = {};
    let status = ''
    state.projectDetail &&
      state.projectDetail.vectorstores &&
      state.projectDetail.vectorstores.forEach((vectorstore) => {
        const { vectorstoreId } = vectorstore;
        const currentVectoreStore = state.projectDetail.orders
          .filter((order) => {
            return (
              order.vectorstoreId === vectorstoreId &&
              order.method === "vectorise"
            );
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
        if (currentVectoreStore) {
          const filteredOrder = payload.filter((item) => {
            return item.queueMethod === "vectorise" && item.orderId == currentVectoreStore.orderId;
          });
          if (filteredOrder.length)
            status =
              filteredOrder.length &&
              filteredOrder.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
              )[0].status;
          VectorStoreStatusList = {
            ...VectorStoreStatusList,
            ...{
              [vectorstoreId]: { orderId: currentVectoreStore.orderId, status, ...vectorstore },
            },
          };
        } else
          VectorStoreStatusList = {
            ...VectorStoreStatusList,
            ...{ [vectorstoreId]: { orderId: "", status: "new", ...vectorstore } },
          };
      });
    state.vectorsStatus = VectorStoreStatusList;
  },
  [Types.mutations.SET_DOCUMENTS]: (state, payload) => {
    state.documents = payload;
  },
  [Types.mutations.SET_SETTINGS]: (state, payload) => {
    state.vectorsStatus = payload;
  },
  [Types.mutations.SET_UPDATE_RESULTS]: (state, payload) => {
    const { tableContent } = payload;
    let list = [];
    let temp = {};
    let latestResponseQnA = {};
    let latestResponseCommentary = {};
    tableContent.forEach((item) => {
      [...state.QnAResults, ...state.commentaryResults].forEach((list) => {
        const type = list.queueMethod;
        if (!temp[list.rowId])
          temp[list.rowId] = { qna: [], lineCommentary: [] };
        else
          temp[list.rowId] = {
            ...temp[list.rowId],
            [type]: [...temp[list.rowId][type], { ...list }],
          };
      });
      if (temp[item.rowId] && temp[item.rowId]["qna"])
        latestResponseQnA = temp[item.rowId].qna.reduce((a, b) =>
          a.ts > b.ts ? a : b,
        );
      if (temp[item.rowId] && temp[item.rowId]["lineCommentary"])
        latestResponseCommentary = temp[item.rowId].lineCommentary.reduce(
          (a, b) => (a.ts > b.ts ? a : b),
        );
      list = [
        ...list,
        {
          ...item,
          QnA:
            latestResponseQnA && latestResponseQnA.response
              ? latestResponseQnA.response.answer
              : "",
          Commentary:
            latestResponseCommentary && latestResponseCommentary.response
              ? latestResponseCommentary.response.answer
              : "",
        },
      ];
    });
    state.pasteTableContent = list;
  },
  [Types.mutations.SET_SELECTED_ROW]: (state, payload) => {
    state.selectedTableRow = payload;
  },
  [Types.mutations.SET_TABLE_STATUS]: (state, payload) => {
    state.tableStatus = payload;
  },
  [Types.mutations.SET_SETTING_LOADING_STATUS]: (state, payload) => {
    state.isUserLoading = payload;
  },
};
