<template>
  <v-dialog
    :value="modal.showDialog"
    persistent
    max-width="350"
    max-height="inherit"
    justify="center"
    :retain-focus="false"
    class="overflow-hidden"
  >
    <v-card>
      <v-card-title class="headline">
        <div class="text-h6"><slot name="header"></slot></div>
      </v-card-title>
      <v-card-text class="py-0"><slot name="body"></slot></v-card-text>
      <v-card-actions class="d-block">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { msalMixin } from "vue-msal";
export default {
  name: "ModalLayout",
  mixins: [msalMixin],
  props: {
    modal: {
      required: true,
      type: Object,
    },
  },
};
</script>
