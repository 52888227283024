export const state = () => ({
  projectDetail: {},
  token: "",
  isLoading: false,
  tables: {},
  tableList: [],
  filteredTableList: [],
  modal: {
    name: "",
    showDialog: false,
    errorMessage: "",
  },
  QnAResults: [],
  commentaryResults: [],
  snackbar: {},
  pasteTableContent: [],
  documents: [],
  selectedTableRow: [],
  settings: [],
  vectorsStatus: {},
  tableStatus: {},
  isUserLoading: false
});
